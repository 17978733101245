export default {
  BUTTON_PRIMARY_CLASS: 'button button--primary',
  BUTTON_SECONDARY_CLASS: 'button button--secondary',

  MAIN_CONTENT_CLASS: `{this.responseState === 'nothing' ? 'max-width' : ''}`,
  PRODUCT_REPEATER_CLASS: 'collection-grid',
  LOAD_MORE_TEXT: 'Show me more products',

  FILTERS_ICON: '<rt-virtual template="icons/filter" />',
  SORT_BY: 'Sort by',

  FACET_TITLE_ICON: `
    <rt-virtual rt-if="this.isCollapsed" template="icons/angle-up" />
    <rt-virtual rt-if="!this.isCollapsed" template="icons/angle-down" />`,
  FACET_FILTER_PLACEHOLDER: 'Search',
  PRICE_FACET_GO_TEXT: 'Apply Price Range',
  SHOW_MORE: 'View All {{ valuesCount }}',
  SHOW_LESS: 'View Less',
  MIN_PRICE: 'From',
  MAX_PRICE: 'To',
  START_OVER: 'All Products',
  CHIPS_DISCARD: '<rt-virtual template="icons/chip-times"></rt-virtual>',

  SEARCH_BOX_CLASS: 'search-modal__form',
  SEARCH_BOX_FORM_CLASS: 'field color-background-1',
  SEARCH_BOX_INPUT_CLASS: 'search__input field__input',
  SEARCH_BOX_PLACEHOLDER: 'Search Part #, Category or Keyword',
  SEARCH_BOX_BUTTON_CLASS: 'search__button field__button',

  GARAGE_ICON: '<rt-virtual template="icons/garage" />',
  GARAGE_SIZE: '<span class="garage-size cart-count-bubble" key="garage-size">{{size}}</span>',
  REMOVE_VEHICLE_ICON: '<rt-virtual template="icons/garage-vehicle-times" />',

  SELECT_YOUR_VEHICLE_BLOCK: `
    <p>shop by vehicle</p>
    <h5>What are you working on?</h5>`,
  GO_TEXT: 'GO!',
  CLEAR_TEXT: 'RESET',

  CHANGE_TEXT: 'Change vehicle',
  VERIFY_FITMENT_FITS: 'Fits my vehicle',
  VERIFY_FITMENT_NOT_FIT: "Doesn't fit my vehicle",
  VERIFY_FITMENT_ICON: `
    <rt-virtual rt-if="this.fits === 'yes' || this.fits === 'universal'" template="icons/check" />
    <rt-virtual rt-if="this.fits === 'no'" template="icons/times" />
    <rt-virtual rt-if="this.fits === 'unknown'" template="icons/questionmark" />
    <rt-virtual rt-if="this.fits === 'aggressive'" template="icons/exclamationmark" />`,
};
